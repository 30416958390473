export const GAME_STRINGS = {
  default:
    "Hello, my name is Simeo, a front end developer based in London, lets use the arrows on your keyboard to learn more, hold left or right arrows to run and TAP up to jump ...",
  info: "After building my first site and enjoying the processes so much, I knew I was due a career change. I gave up my job to explore my passion, and completed the le wagon coding bootcamp. I have been working in FinTech for the last 1.7 years ...",
  kate: "You found Kate, I have been fan-girling on 'that hill' long before Stranger things made her cool ... 🎵 ",
  about:
    "This is my laptop, its where I code and test projects using React, Typescript, Cypress and more ...",
  linked: "Click the button to visit my profile ...",
  jumpInstruction: "Hey, look for items to jump onto and learn more ->  ...",
  emptyLeftInstruction:
    "You can go left but you wont find much, lets go right ...",
  smallScreens: "This experience is not yet optimized for smaller screens ...",
};
